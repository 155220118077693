import React from 'react'
import styled from '@emotion/styled'

const Container = styled.div`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 100px 0;
  background-color: 252839;
`
const Timeline = styled.div`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  width: 80%;
  height: auto;
  max-width: 800px;
  margin: 0 auto;
  position: relative;
  ul {
    list-style: none;
  }
  ul li {
    padding: 20px;
    background-color: #1b1d2a;
    color: white;
    border-radius: 10px;
    margin-bottom: 20px;
  }
  ul li:last-child {
    margin-bottom: 0;
  }
  @media only screen and (min-width: 768px) {
    :before {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 2px;
      height: 100%;
      background-color: #1b1d2a;
    }
    ul li {
      width: 50%;
      position: relative;
      margin-bottom: 50px;
      transition: 0.3s;
    }
    ul li:nth-of-type(odd) {
      float: left;
      clear: right;
      transform: translateX(-30px);
      border-radius: 20px 0px 20px 20px;
    }
    ul li:nth-of-type(even) {
      float: right;
      clear: left;
      transform: translateX(30px);
      border-radius: 0px 20px 20px 20px;
    }
    ul li::before {
      content: '';
      position: absolute;
      height: 20px;
      width: 20px;
      border-radius: 50%;
      background-color: #1b1d2a;
      transition: 0.3s;
      top: 0px;
    }
    ul li:nth-of-type(odd)::before {
      transform: translate(50%, -50%);
      right: -30px;
    }
    ul li:nth-of-type(even)::before {
      transform: translate(-50%, -50%);
      left: -30px;
    }
    transition: 0.3s;

    ul li:hover::before {
      background-color: rgb(241, 182, 49);
    }
  }
`
const TimelineComponent = styled.div`
  h1 {
    font-weight: 500;
    font-size: 25px;
    line-height: 30px;
    margin-bottom: 10px;
    color: #fff;
  }
  p {
    font-size: 16px;
    line-height: 30px;
    font-weight: 300;
  }
  .date {
    font-size: 12px;
    font-weight: 300;
    margin-bottom: 10px;
    letter-spacing: 2px;
  }
  @media only screen and (min-width: 768px) {
    .date {
      position: absolute;
      top: -30px;
    }
  }
`
const SevenStepParagraph = styled.h4`
  font-style: normal;
  font-weight: 300;
  font-size: 19px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: #ffffff;
  justify-content: center;
  align-items: center;
  max-width: 75%;
  text-align: center;
  margin: auto;
`

export default () => {
  return (
    <div>
      <SevenStepParagraph>
        Every business is different, and the software that works for one may not suit another. With that in mind, we offer a 6 step process
        to guide you through your thinking and development. From Planning and Screens and Flows to Designing and Launching your solution,
        You are kept in the loop through the entire process each one of your needs is taken into account
      </SevenStepParagraph>{' '}
      <Container className="container">
        <Timeline className="timeline">
          <ul>
            <li>
              <TimelineComponent>
                <h1>Planning</h1>
                <p>
                  One of the most important parts of the software and app development process is working out exactly what needs doing. We
                  already have a rough idea but we dig far deeper and work out the tiny intricacies of what needs developing and what
                  doesn't
                </p>
              </TimelineComponent>
            </li>
            <li>
              <TimelineComponent>
                <h1>Screens & Flows</h1>
                <p>
                  Next, we'll get working on the layout of the software and the key flows that the system is built around. At this point,
                  80% of the system will be designed from a visual perspective{' '}
                </p>
              </TimelineComponent>
            </li>
            <li>
              <TimelineComponent>
                <h1>Demonstration</h1>
                <p>
                  Once the screens are designed, we run you through a live demonstration on exactly how the flows will work and how you'll
                  use your new software or app. We will also demonstrate how all of your data and payments will be kept secure throughout
                  the system. Once you're happy and satisfied with the screens, the flows, and the data coming in/out, you sign this stage
                  off and we get to work making it a reality{' '}
                </p>
              </TimelineComponent>
            </li>{' '}
            <li>
              <TimelineComponent>
                <h1>Development</h1>
                <p>
                  At this point, we go a little quiet and work on developing your software or app solution. We give you progress reports as
                  we reach milestones. There may also be occasions where we need something clarified or checked by you. This is usually the
                  longest part of the process{' '}
                </p>
              </TimelineComponent>
            </li>
            <li>
              <TimelineComponent>
                <h1>Review</h1>
                <p>
                  Nothing is perfect the first time. This is why we make sure that our lines of communication are especially open during
                  your first few weeks. Our development team will be ready and waiting for any changes that need to be made to ensure your
                  software or app solution is as well built as possible{' '}
                </p>
              </TimelineComponent>
            </li>
            <li>
              <TimelineComponent>
                <h1>Launch</h1>
                <p>
                  This is the point when we demonstrate the final product and give you all the training to use your new software or app
                  solution{' '}
                </p>
              </TimelineComponent>
            </li>
          </ul>
        </Timeline>
      </Container>
    </div>
  )
}
