import React from 'react'
import Particles from 'react-tsparticles'
import styled from '@emotion/styled'

const ParticleWidth = styled(Particles)`
  .tsparticles-canvas-el {
    width: 100vw !important;
    height: 100vh !important;
    pointer-events: initial;
    background-color: #252839;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    @media only screen and (max-width: 373px) {
      width: 134vw !important;
    }
  }
`

export default () => {
  const ParticleTop = styled.div``
  return (
    <ParticleTop>
      <ParticleWidth
        id="tsparticles"
        options={{
          background: {
            color: '#252839'
          },
          detectRetina: false,
          fpsLimit: 30,
          interactivity: {
            detectsOn: 'canvas',
            events: {
              resize: true
            }
          },
          particles: {
            color: {
              value: 'f1b631'
            },
            number: {
              density: {
                enable: true,
                area: 1080
              },
              limit: 0,
              value: 400
            },
            opacity: {
              animation: {
                enable: true,
                minimumValue: 0.05,
                speed: 1,
                sync: false
              },
              random: {
                enable: true,
                minimumValue: 0.05
              },
              value: 1
            },
            shape: {
              type: 'circle'
            },
            size: {
              random: {
                enable: true,
                minimumValue: 0.5
              },
              value: 1
            }
          }
        }}
      />
    </ParticleTop>
  )
}
